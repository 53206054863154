<template>
  <div white>
    <v-row>
      <v-col
        ><h1 class="text-center mt-4">
          <span v-if="vrPatientFirstName !== null">{{ vrPatientFirstName }}</span
          >&nbsp;
          <span v-if="vrPatientLastName !== null">{{ vrPatientLastName }}</span
          >
        </h1></v-col
      >
    </v-row>
    <v-row class="mt-0 pb-1">
      <v-col class="pt-0 pb-1">
        <!-- <v-tabs  height="36" 
                show-arrows next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"> -->
        <v-tabs height="30">
          <v-tab class="py-0" to="/patient">Demographics</v-tab>
          <v-tab v-if="vrPatientID !== null" class="py-0" to="/scheduling"
            >Scheduling</v-tab
          >
          <v-tab v-if="vrPatientID !== null" class="py-0" to="/insurance"
            >Insurance</v-tab
          >
          <v-tab v-if="vrPatientID !== null" class="py-0" to="/devices"
            >Devices</v-tab
          >
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import store from "@/store/index";
export default {
  data: () => ({}),
  computed: {
    vrPatientID() {
      return store.state.patientId;
    },
    vrPatientFirstName() {
      return store.state.patientInfo.first_name;
    },
    vrPatientLastName() {
      return store.state.patientInfo.last_name;
    },
  },
};
</script>
<style scoped>
.v-app-bar {
  background: #fff;
}
/* .sub-header{ position: fixed; left: 0px; right: 0px; top: 44px; z-index: 4; background: #fff;}  */
h1 {
  color: #113b62;
  font-size: 1.4rem;
  line-height: 36px;
  font-weight: 700;
  font-family: roboto;
  letter-spacing: 0.02em;
}
.v-slide-group__content {
  justify-content: center !important;
}
</style>
