import notifications from "@/components/common/notifications";
import listAPI from "@/api/listAPI.js";
import patientAPI from "@/api/patientAPI.js";
import store from "@/store/index";
import SubHeader from "@/components/SubHeader.vue";
import Header from "@/components/Header.vue";
import validations from "@/validations/index";
import permissions from "@/api/permissions.js";

export default {
  components: {
    Header,
    SubHeader,
    notifications,
  },
  data: () => ({
    // page:1,

    // Dialog box
    deviceInfo: false,
    addPatientDevice: false,
    devices: [],

    //options: { page: 1, itemsPerPage: 25 },
    options: {},
    totalRecords: 0,
    tblLoading: false,
    vrAppointments: [],
    headers: [
      // { sortable: false, text: "#", value: "id" },
      { sortable: false, text: "MANUFACTURER", value: "manufacturer" },
      { sortable: false, text: "TYPE", value: "device_type" },
      { sortable: false, text: "MODEL", value: "model" },
      { sortable: false, text: "SERIAL", value: "device_serial" },
      { sortable: false, text: "IMPLANT DATE", value: "implant_date_display" },
      { sortable: false, text: "EXPLANT DATE", value: "explant_date_display" },
      { sortable: false, text: "", value: "action" },
    ],
    vrDeviceType: null,
    vrPatientDevice: {},
    vrDeviceInfo: {},
    isLoadingModel: false,
    isLoadingCarrierPrimary: false,
    vrKeyWordCarrierPrimary: null,
    vrCarrierPrimary: null,
    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: { },
    //---------------------------------------------------
  }),
  watch: {
    options: {
      handler() {
        this.loadPatientScheduling();
      },
      deep: true,
    },
    vrKeyWordCarrierPrimary(val) {
      if (val == null) {
        this.vrInsurancePrimary = {};
        return;
      }
      this.searchInsurer(val, "vrCarrierPrimary", "isLoadingCarrierPrimary");
    },
  },
  methods: {
    addPatientDeviceOpen() {
      this.vrPatientDevice = {};
      this.vrPatientDevice.patient_id = this.vrPatientID;
      this.addPatientDevice = true;
    },
    addDeviceMasterOpen() {
      this.vrDeviceInfo = {};
      this.deviceInfo = true;
    },
    deleteSchedule(vrItem) {
      //this.formData = item

      if (!confirm("Are you sure, you want to delete?")) {
        return false;
      }
      
      patientAPI
        .deleteDeviceData({
          id: vrItem.id,
        })
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .then(() => {
          this.loadPatientScheduling();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    EditSchedule(item) {
      this.vrPatientDevice.id = item.id;
      this.vrPatientDevice.patient_id = this.vrPatientID;
      //this.vrPatientDevice.model = item.model
      this.vrPatientDevice.device_serial = item.device_serial;
      this.vrPatientDevice.implant_date = item.implant_date;
      this.vrPatientDevice.explant_date = item.explant_date;
      //alert(item.model)
      this.vrKeyWordCarrierPrimary = item.model;
      this.vrPatientDevice.model = {
        model: item.model,
        device_id: item.device_id,
      };

      this.addPatientDevice = true;
    },

    SavePatientDevice() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;
      let isSuccess = false;

      if (isValid) {
        isValid = validations.hasValue(this.vrPatientDevice.model);
      }
      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      patientAPI
        .savePatientDeviceData(this.vrPatientDevice)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              this.vrPatientDevice = {};
              this.addPatientDevice = false;
              isSuccess = response.data.status;
            }

            //console.log(response);
            if (
              response.data.results != null &&
              response.data.results.confirm != null
            ) {
              if (response.data.results.confirm == "Y") {
                if (confirm(response.data.message)) {
                  this.vrPatientDevice.ignore_serial_check = "Y";
                  this.SavePatientDevice();
                }
                this.vrNotify.visible = false;
                return false;
              }
            }
          }
        })
        .then(() => {
          if (isSuccess) {
            //Refresh scheduling table with updated data
            this.loadPatientScheduling();

            //Hide the popup
            this.deviceInfo = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },

    SaveDeviceMaster() {
      //----------------------------------------------
      //Required field validations
      let isValid = true;

      if (isValid) {
        isValid = validations.hasValue(this.vrDeviceInfo.model);
      }
      if (isValid) {
        isValid = validations.hasValue(this.vrDeviceInfo.manufacturer);
      }
      if (isValid) {
        isValid = validations.hasValue(this.vrDeviceInfo.device_type_id);
      }
      if (!isValid) {
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }
      //----------------------------------------------

      patientAPI
        .saveDeviceData(this.vrDeviceInfo)
        .then((response) => {
          if (response.data !== null) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;

            if (response.data.status) {
              this.vrPatientDevice = {};
              this.deviceInfo = false;
            }
          }
        })
        .then(() => {
          //Refresh scheduling table with updated data
          this.loadPatientScheduling();

          //Hide the popup
          this.deviceInfo = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          //this[vrLoadingControlName] = false;
        });
    },

    loadPatientScheduling() {
      patientAPI
        .getPatientGetDeviceList({
          patient_id: this.vrPatientID,
          pagination: this.options,
        })
        .then((response) => {
          if (response.data.results !== null)
            this.vrAppointments = response.data.results.devices;
          this.totalRecords = response.data.results.devicesCount.total_count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },

    //-------------------------------------------------------
    //Search Diagnosis
    //-------------------------------------------------------
    searchInsurer(val, vrControlName, vrLoadingControlName) {
      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      if (val === null) return; //Return

      this[vrLoadingControlName] = true; //initiate loading

      listAPI
        .getDeviceModelList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null)
            this.vrCarrierPrimary = response.data.results.Devices;
          else this.vrCarrierPrimary = [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this[vrLoadingControlName] = false;
        });

      this[vrLoadingControlName] = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------
  },
  beforeMount() {
    listAPI
      .getEventTypeList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrEventType = response.data.results.Event_Type;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getDeviceType()
      .then((response) => {
        if (response.data.results !== null)
          this.vrDeviceType = response.data.results.Device_Type;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getRemoteServiceList()
      .then((response) => {
        if (response.data.results !== null)
          this.vrRemoteServiceList = response.data.results.Remote_Service;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    //this.options.page = 1;
    //this.loadPatientScheduling();
  },
  computed: {
    vrPatientID() {
      return store.state.patientId;
    },
    vrNotify() {
      return store.state.notify;
    },
    vrRedirectURL() {
      return store.state.redirectURL;
    },
  },
  mounted() {},

  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
